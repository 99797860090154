svg.template {
  border: 10px solid #ddd;
  width: 100%;
  height: auto;
}

#root > nav + .container {
  margin-top: 1rem;
}

.edit-nav {
  background-color: #ffffffee;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}
