@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Quicksand:wght@500&family=Source+Code+Pro:wght@400");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
}

code {
  font-family: "Source Code Pro", monospace;
}

.list-group-item {
  background-color: transparent;
}

.form-label {
  margin-bottom: 0.25rem;
}

@media (min-width: 768px) {
  .position-md-static {
    position: static;
  }
}

.alert {
  max-height: 500px;
  transition: all 0.5s ease-out, padding 0.1s;
}

.alert-hidden {
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  transition-duration: 0.25s;
}

.form-control-color.form-control.is-valid,
.was-validated .form-control-color.form-control:valid {
  padding-right: 0.375rem;
  background-image: none;
}
