.tippy-box .list-group-item-action:focus,
.tippy-box .list-group-item-action:hover {
  z-index: 1;
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}

.tippy-box .list-group-flush > .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tippy-box .card-body + .list-group-flush > .list-group-item:first-child {
  border-top-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tippy-box .list-group-flush > .list-group-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tippy-content > div {
  margin: -5px -9px;
}

#navbar .nav-item {
  margin-top: 0.25rem;
  padding-top: 0.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

@media (min-width: 992px) {
  #navbar .nav-item {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
