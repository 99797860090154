@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Quicksand:wght@500&family=Source+Code+Pro:wght@400);
.nav-steps {
  --bs-breadcrumb-divider: ">";
}

.mini-overlay {
  background-color: #00000033;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.form-control.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.tippy-box .list-group-item-action:focus,
.tippy-box .list-group-item-action:hover {
  z-index: 1;
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}

.tippy-box .list-group-flush > .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tippy-box .card-body + .list-group-flush > .list-group-item:first-child {
  border-top-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tippy-box .list-group-flush > .list-group-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tippy-content > div {
  margin: -5px -9px;
}

#navbar .nav-item {
  margin-top: 0.25rem;
  padding-top: 0.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

@media (min-width: 992px) {
  #navbar .nav-item {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

svg.template {
  border: 10px solid #ddd;
  width: 100%;
  height: auto;
}

#root > nav + .container {
  margin-top: 1rem;
}

.edit-nav {
  background-color: #ffffffee;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
}

code {
  font-family: "Source Code Pro", monospace;
}

.list-group-item {
  background-color: transparent;
}

.form-label {
  margin-bottom: 0.25rem;
}

@media (min-width: 768px) {
  .position-md-static {
    position: static;
  }
}

.alert {
  max-height: 500px;
  transition: all 0.5s ease-out, padding 0.1s;
}

.alert-hidden {
  margin: 0;
  padding: 0;
  max-height: 0;
  opacity: 0;
  transition-duration: 0.25s;
}

.form-control-color.form-control.is-valid,
.was-validated .form-control-color.form-control:valid {
  padding-right: 0.375rem;
  background-image: none;
}

